import { graphql } from "gatsby";
import Img, { FluidObject, GatsbyImageProps } from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockKnowledgeHero_ContentfulBlockKnowledgeHeroFragment } from "../../../types/graphql-types";
import Center from "../Center";
import { HEADER_HEIGHT } from "../Header";
import BlockArticles from "./BlockArticles";
import BlockCategories from "./BlockCategories";
import BlockMediaItemListsCard from "./BlockMediaItemListsCard";
import BlockNewsletterRegistration from "./BlockNewsletterRegistration";

type BlockKnowledgeHeroProps = {
  contentBlock: BlockKnowledgeHero_ContentfulBlockKnowledgeHeroFragment;
};

const StyledHero = styled.div`
  margin-top: 6.5rem;
  position: relative;
  padding-top: ${(props) => props.theme.spacings.huge};
  margin-bottom: ${(props) => props.theme.spacings.huge};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-top: ${(props) => props.theme.spacings.gigantic};
    margin-bottom: ${(props) => props.theme.spacings.gigantic};
  }
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    padding-bottom: ${(props) => props.theme.spacings.huge};
    margin-bottom: -${(props) => props.theme.spacings.huge};
  }

  > div:not(:first-of-type) {
    position: relative;
    z-index: 1;
  }
`;

const BackgroundGradient = styled.div`
  position: absolute;
  /* A negative top to overlap the gradient over the header. The header height is 64px. */
  top: -${HEADER_HEIGHT};
  left: 0;
  right: 0;
  /* makes the gradient start even below the hero */
  bottom: -90px;
  transform-origin: 0;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.palette.brand[400]} 0%,
    ${(props) => props.theme.palette.brand[500]} 50%,
    ${(props) => props.theme.palette.brand[600]} 100%
  );
  overflow: hidden;
  max-height: 60%;
  transform: skewY(-25deg);

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    max-height: 650px;
  }
  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    transform: skewY(-22deg);
    max-height: 700px;
  }
  @media (min-width: 75em) {
    transform: skewY(-16deg);
  }
  @media (min-width: 90em) {
    max-height: 752px;
  }
`;

const SkewedImg: React.ComponentType<GatsbyImageProps> = styled(Img)`
  height: 100%;
  opacity: 0.2;
  transform-origin: 0;
  filter: grayscale(100%);
  transform: skewY(25deg);

  @media (min-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
    transform: skewY(22deg);
  }
  @media (min-width: 75em) {
    transform: skewY(16deg);
  }
`;

const StyledCenter = styled(Center)<{ isNewsletterRegistration?: boolean }>`
  max-width: ${(props) => props.theme.layout.articleWidth};
  margin-bottom: ${(props) => props.theme.spacings.huge};

  ${(props) =>
    props.isNewsletterRegistration &&
    css`
      @media (min-width: ${props.theme.layout.mobileBreakpoint}) {
        margin-bottom: ${props.theme.spacings.small};
      }
    `}
`;

const StyledTitleFirstLine = styled(Text)`
  color: ${(props) => props.theme.palette.white};
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
`;
const StyledTitleSecondLine = styled(Text)`
  color: ${(props) => props.theme.palette.white};
`;

const BlockKnowledgeHero: React.FC<BlockKnowledgeHeroProps> = (props) => (
  <StyledHero>
    <BackgroundGradient>
      <SkewedImg
        fluid={props.contentBlock.image?.fluid as FluidObject}
        loading="eager"
      />
    </BackgroundGradient>
    <StyledCenter
      isNewsletterRegistration={Boolean(
        props.contentBlock.contentBlock?.__typename ===
          "ContentfulBlockNewsletterRegistration",
      )}
    >
      <Spacings.Stack scale="medium">
        <StyledTitleFirstLine textStyle="headline1">
          {props.contentBlock.titleFirstLine}
        </StyledTitleFirstLine>
        {props.contentBlock.titleSecondLine && (
          <StyledTitleSecondLine textStyle="bodyProlonged" as="h2">
            {props.contentBlock.titleSecondLine}
          </StyledTitleSecondLine>
        )}
      </Spacings.Stack>
    </StyledCenter>

    {props.contentBlock.contentBlock?.__typename ===
      "ContentfulBlockArticles" && (
      <BlockArticles contentBlock={props.contentBlock.contentBlock} />
    )}
    {props.contentBlock.contentBlock?.__typename ===
      "ContentfulBlockCategories" && (
      <BlockCategories contentBlock={props.contentBlock.contentBlock} />
    )}
    {props.contentBlock.contentBlock?.__typename ===
      "ContentfulBlockMediaItemListsCard" && (
      <BlockMediaItemListsCard contentBlock={props.contentBlock.contentBlock} />
    )}
    {props.contentBlock.contentBlock?.__typename ===
      "ContentfulBlockNewsletterRegistration" && (
      <BlockNewsletterRegistration
        contentBlock={props.contentBlock.contentBlock}
      />
    )}
  </StyledHero>
);

export const BlockKnowledgeHeroFragment = graphql`
  fragment BlockKnowledgeHero_ContentfulBlockKnowledgeHero on ContentfulBlockKnowledgeHero {
    image {
      fluid(maxWidth: 2000, quality: 70) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    titleFirstLine
    titleSecondLine
    contentBlock {
      __typename
      ... on ContentfulBlockArticles {
        ...BlockArticles_ContentfulBlockArticles
      }
      ... on ContentfulBlockCategories {
        ...BlockCategories_ContentfulBlockCategories
      }
      ... on ContentfulBlockMediaItemListsCard {
        ...BlockMediaItemListsCard_ContentfulBlockMediaItemListsCard
      }
      ... on ContentfulBlockNewsletterRegistration {
        ...BlockNewsletterRegistration_ContentfulBlockNewsletterRegistration
      }
    }
  }
`;

export default BlockKnowledgeHero;
