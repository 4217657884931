import { useInView } from "react-intersection-observer";
import React, { useContext } from "react";
import styled from "styled-components";

import { StickyHeroHeader_ContentfulBlockHeroFragment } from "types/graphql-types";
import { GlobalStateContext } from "../../../GlobalStateContextProvider";
import StickyHeroHeader from ".";

type StickyHeroWithObserverWrapperProps = {
  observerTarget: React.ReactNode;
  contentBlock: StickyHeroHeader_ContentfulBlockHeroFragment;
};

const StickyHeroHeaderWrapper = styled.div<{
  hide: {
    onDesktop: boolean;
    onMobile: boolean;
  };
}>`
  /* Higher specificity needed to override the default styles inherited from content blocks container */
  &#sticky-hero-header {
    margin-top: 0;
  }

  display: ${(props) => (props.hide.onMobile ? "none" : "block")};

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: ${(props) => (props.hide.onDesktop ? "none" : "block")};
  }
`;

/**
 * This component wraps the StickyHeroHeader component with
 * an IntersectionObserver so whenever the user scrolls past the
 * observerTarget, the StickyHeroHeader will be displayed. This makes
 * rendering the BlockHero component inside the Carousel component simpler
 */
const StickyHeroWithObserverWrapper: React.FC<
  StickyHeroWithObserverWrapperProps
> = ({ observerTarget, contentBlock }) => {
  const [ref, inView, entry] = useInView();

  const { globalState } = useContext(GlobalStateContext);
  return (
    <>
      <div ref={ref}>{observerTarget}</div>
      <StickyHeroHeaderWrapper
        id="sticky-hero-header"
        hide={{
          onDesktop: globalState.hideStickyHeader.onDesktop,
          onMobile: globalState.hideStickyHeader.onMobile,
        }}
      >
        <StickyHeroHeader
          visible={!inView && Boolean(entry)}
          contentBlock={contentBlock}
        />
      </StickyHeroHeaderWrapper>
    </>
  );
};

export default StickyHeroWithObserverWrapper;
