import React from "react";
import { graphql } from "gatsby";
import {
  BlockCarousel_ContentfulBlockCarouselFragment,
  BlockHero_ContentfulBlockHeroFragment,
} from "types/graphql-types";

import Carousel from "@finanzchef24gmbh/design-system/src/Carousel";
import BlockHero from "../BlockHero";
import StickyHeroWithObserverWrapper from "../BlockHero/StickyHeroHeader/StickyHeroWithObserverWrapper";

type BlockCarouselProps = {
  contentBlock: BlockCarousel_ContentfulBlockCarouselFragment;
};
const BlockCarousel: React.FC<BlockCarouselProps> = ({ contentBlock }) => {
  if (!contentBlock.carouselItem) return;

  const blockType = contentBlock.carouselItem[0]?.__typename;

  switch (blockType) {
    case "ContentfulBlockHero":
      const slides = contentBlock.carouselItem.map((item, idx) => (
        <BlockHero
          contentBlock={item as BlockHero_ContentfulBlockHeroFragment}
          changeMainTitleTagTo={idx === 0 ? "h1" : "h2"}
        />
      ));
      return (
        <StickyHeroWithObserverWrapper
          contentBlock={
            contentBlock.carouselItem && (contentBlock.carouselItem[0] as any)
          }
          observerTarget={
            <Carousel
              slides={slides}
              hideNavigationArrows={contentBlock.hideNavigationArrows ?? false}
              autoSlide={contentBlock.autoSlide ?? undefined}
              autoSlideInterval={contentBlock.autoSlideInterval ?? undefined}
            />
          }
        />
      );
      break;
    default:
      return null;
  }
};

export const blockCarouselFragment = graphql`
  fragment BlockCarousel_ContentfulBlockCarousel on ContentfulBlockCarousel {
    autoSlide
    hideNavigationArrows
    autoSlideInterval
    carouselItem {
      ... on ContentfulBlockHero {
        __typename
        ...BlockHero_ContentfulBlockHero
      }
    }
  }
`;

export default BlockCarousel;
