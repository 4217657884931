import React, { useContext } from "react";

type Crumb = {
  slug: string;
  label: string | null;
};

export type Context = {
  crumbs: Crumb[];
  isTopBarVisible?: boolean;
};

const PageContext = React.createContext<null | Context>(null);

export const usePageContext = (): Context => {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error("usePageContext must be used within a PageContextProvider");
  }

  if (context === null) {
    throw new Error("usePageContext has not been initialized");
  }
  return context;
};

export const PageContextProvider = PageContext.Provider;
export default PageContext;
