import { graphql } from "gatsby";
import React from "react";

import { BlockHero_ContentfulBlockHeroFragment } from "../../../../types/graphql-types";
import NewHeroConcept from "./NewHeroConcept";
import OldHeroConcept from "./OldHeroConcept";

export type BlockHeroProps = {
  changeMainTitleTagTo?: "h1" | "h2";
  contentBlock: BlockHero_ContentfulBlockHeroFragment;
  useOttonovaWidget?: boolean;
};

const BlockHero: React.FC<BlockHeroProps> = (props) => (
  <>
    {props.contentBlock.useNewLpHeroConcept ? (
      <NewHeroConcept
        contentBlock={props.contentBlock}
        changeMainTitleTagTo={props.changeMainTitleTagTo || "h1"}
        useOttonovaWidget={props.useOttonovaWidget || false}
      />
    ) : (
      <OldHeroConcept contentBlock={props.contentBlock} />
    )}
  </>
);

export const blockHeroFragment = graphql`
  fragment BlockHero_ContentfulBlockHero on ContentfulBlockHero {
    useNewLpHeroConcept
    titleFirstLine
    titleSecondLine
    socialProofHtml {
      html: socialProofHtml
    }
    socialProofRichText {
      json
    }
    hideSocialProofOnMobile
    image {
      fixed(width: 500, height: 375) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    heroImage {
      file {
        url
      }
      fixed(width: 360, height: 334, quality: 90) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    backgroundImage {
      file {
        url
      }
      fluid(quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    newConceptBackgroundImage: backgroundImage {
      fluid(maxWidth: 1600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    hideTrustSeals
    hideInsurerLogos
    badge
    ctaSectionTitle
    ctaSectionText {
      ctaSectionText
    }
    showIndustrySelection
    industryId
    industrySelectionProductCombinationId
    industryIdsForIndustrySelection
    industrySelectionTarget
    industryAutocompletePlaceholder
    ctaButton {
      buttonText
      buttonLink
      buttonType
      buttonIcon
      buttonIconPosition
    }
    ctaButtonTextMobile
    secondaryButton {
      buttonText
      buttonLink
      buttonType
      buttonIcon
      buttonIconPosition
    }
    secondaryLinkText
    tenant
    quickIndustrySelectionsTitle
    quickIndustrySelections {
      id
      title
      industryId
      productTypeCombinationId
      icon {
        file {
          url
        }
      }
    }
    addonBoxImage {
      file {
        url
      }
    }
    addonBoxSingleLineRichText {
      json
    }
    savingsBadgeWithModalOverlay {
      ...SavingsBadge_ContentfulSavingsBadge
    }

    ...StickyHeroHeader_ContentfulBlockHero
  }
`;

export default BlockHero;
