import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";

import { PageContextProvider, Context } from "../context/PageContext";
import {
  BlockBlogs_ContentfulBlockBlogsFragment,
  BlockArticles_ContentfulBlockArticlesFragment,
  ContentfulBasicLandingPage,
  LandingPageQueryQuery,
} from "../../types/graphql-types";
import BlockNotificationBar from "../components/blocks/BlockNotificationBar";
import ContentBlocks from "../components/ContentBlocks";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import TopHeader from "../components/TopHeader";
import UnsupportedBrowsersBar from "../components/UnsupportedBrowsersBar";

const GlobalStyles = createGlobalStyle`
  body {
    background: none;
  }
`;

type LandingPageContext = {
  containerWidth: number;
  crumbs: Context["crumbs"];
  globalHideHeaderPhoneNumber?: boolean;
  randomArticleCards?: BlockArticles_ContentfulBlockArticlesFragment["articleCards"];
  latestDynamicBlogs?: {
    [key: string]: BlockBlogs_ContentfulBlockBlogsFragment["blogCards"];
  };
};

type LandingPageProps = {
  data: LandingPageQueryQuery;
  pageContext: LandingPageContext;
};

type HeaderBackgroundColor =
  ContentfulBasicLandingPage["headerBackgroundColor"];

const LandingPage: React.FC<LandingPageProps> = ({ data, pageContext }) => {
  const { contentfulBasicLandingPage } = data;

  const [isUnsupportedBrowser, setIsUnsupportedBrowser] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const regexPattern = /MSIE\s|Trident\//;

    if (regexPattern.test(userAgent)) {
      setIsUnsupportedBrowser(true);
    }
  }, []);

  const isTopBarVisible =
    contentfulBasicLandingPage?.topNewsBar?.layout === "TopNewsBar" &&
    !contentfulBasicLandingPage.topNewsBar.hideTopNewsBar;

  return (
    <PageContextProvider
      value={{ crumbs: pageContext.crumbs, isTopBarVisible }}
    >
      <Layout containerWidth={pageContext.containerWidth}>
        {contentfulBasicLandingPage?.contentBlocks?.some(
          (block) => block?.type === "ContentfulBlockBlogHero",
        ) && <GlobalStyles />}

        {contentfulBasicLandingPage?.metaTitle &&
        contentfulBasicLandingPage.pageLink?.slug ? (
          <Seo
            title={contentfulBasicLandingPage.metaTitle}
            description={
              contentfulBasicLandingPage.metaDescription || undefined
            }
            metaRobotsTag={
              contentfulBasicLandingPage.metaRobotsTag || undefined
            }
            slug={contentfulBasicLandingPage.pageLink?.slug}
            previewImage={contentfulBasicLandingPage.previewImage || undefined}
            metaStructuredData={contentfulBasicLandingPage.metaStructuredData}
          />
        ) : null}

        {isUnsupportedBrowser ? <UnsupportedBrowsersBar /> : null}

        {contentfulBasicLandingPage ? (
          <TopHeader
            topNewsBar={
              contentfulBasicLandingPage?.topNewsBar?.layout === "TopNewsBar" &&
              !contentfulBasicLandingPage.topNewsBar.hideTopNewsBar ? (
                <BlockNotificationBar
                  contentBlock={contentfulBasicLandingPage.topNewsBar}
                />
              ) : null
            }
          />
        ) : null}

        {contentfulBasicLandingPage ? (
          <Header
            headerBackgroundColor={
              contentfulBasicLandingPage.headerBackgroundColor as HeaderBackgroundColor
            }
            hideHeaderPhoneNumber={
              contentfulBasicLandingPage.hideHeaderPhoneNumber !== null
                ? contentfulBasicLandingPage.hideHeaderPhoneNumber
                : pageContext.globalHideHeaderPhoneNumber
            }
            navigation={contentfulBasicLandingPage.navigation || undefined}
          />
        ) : null}

        {contentfulBasicLandingPage?.contentBlocks ? (
          <ContentBlocks
            contentBlocks={contentfulBasicLandingPage.contentBlocks}
            {...(pageContext.randomArticleCards && {
              randomArticleCards: pageContext.randomArticleCards,
            })}
            {...(pageContext.latestDynamicBlogs && {
              latestDynamicBlogs: pageContext.latestDynamicBlogs,
            })}
            // Unused at the moment, but needed for the future as per Johannes H.
            // {...(contentfulBasicLandingPage.contentBlocks.some(
            //   (block) => block?.type === "ContentfulBlockAd",
            // ) && {
            //   pageId: contentfulBasicLandingPage.contentful_id,
            // })}
            {...(contentfulBasicLandingPage.contentBlocks.some(
              (block) => block?.type === "ContentfulBlockHero",
            ) && {
              pageLink: contentfulBasicLandingPage.pageLink,
            })}
          />
        ) : (
          <div>Page not found</div>
        )}
      </Layout>
    </PageContextProvider>
  );
};

export const LandingPageQuery = graphql`
  query LandingPageQuery($slug: String!, $containerWidth: Int!) {
    contentfulBasicLandingPage(pageLink: { slug: { eq: $slug } }) {
      contentful_id
      metaTitle
      metaDescription
      metaStructuredData {
        internal {
          content
        }
      }
      previewImage {
        file {
          contentType
        }
        fixed(width: 1200, height: 630) {
          src
        }
      }
      metaRobotsTag
      pageLink {
        slug
      }
      navigation {
        ...NavigationMenu_ContentfulNavigationMenu
      }
      hideHeaderPhoneNumber
      headerBackgroundColor
      topNewsBar {
        ...BlockNotificationBar_ContentfulBlockNotificationBar
      }
      contentBlocks {
        ...ContentBlocksFragment
      }
    }
  }
`;

export default LandingPage;
