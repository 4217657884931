import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import React from "react";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { BlockBlogHero_ContentfulBlockBlogHeroFragment } from "../../../types/graphql-types";
import Center from "../Center";
import { TOP_HEADER_HEIGHT } from "../TopHeader";
import { HEADER_HEIGHT } from "../Header";
import { usePageContext } from "../../context/PageContext";

const StyledCenter = styled(Center)<{ isTopBarVisible: boolean }>`
  max-width: ${(props) => props.theme.layout.articleWidth};
  padding-top: ${(props) => props.theme.spacings.huge};
  margin-top: calc(${TOP_HEADER_HEIGHT} + ${HEADER_HEIGHT});

  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-top: ${(props) => props.theme.spacings.gigantic};
  }

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    margin-top: ${(props) => (props.isTopBarVisible ? TOP_HEADER_HEIGHT : 0)};
    padding-top: ${(props) =>
      props.isTopBarVisible ? TOP_HEADER_HEIGHT : "4.5rem"};
  }
`;

const StyledHeading = styled(Text)<{ isSingleBlog: boolean }>`
  font-size: calc(1.7rem + 1.3vw);
  font-weight: ${(props) => props.theme.typography.weights.semiBold};
  position: relative;
  text-align: center;

  ${(props) =>
    !props.isSingleBlog &&
    css`
      :before {
        content: "";
        background: linear-gradient(
          181deg,
          rgba(255, 255, 255, 0) 30%,
          ${props.theme.palette.mint[300]} 32%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 116%;
        height: 100%;
        transform: skew(-20deg, -10deg) rotate(11deg) translateX(-8%);
      }
    `};
`;

const StyledSubheading = styled(Text)`
  font-size: calc(1.4rem + 0.4vw);
  text-align: center;
`;

const StyledImg = styled(Img)<GatsbyImageFluidProps>`
  width: 100%;

  img {
    border-radius: ${(props) => props.theme.borders.radius.medium}px;
  }
`;

const StyledImageCaption = styled(Text)`
  color: ${(props) => props.theme.palette.mint[600]};
  text-align: center;
`;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_NODE, children: any) => (
      <StyledImageCaption isStrong>{children}</StyledImageCaption>
    ),
  },
};

type BlockBlogHeroProps = {
  contentBlock: BlockBlogHero_ContentfulBlockBlogHeroFragment;
};

const BlockBlogHero: React.FC<BlockBlogHeroProps> = (props) => {
  const { isTopBarVisible } = usePageContext();
  return (
    <StyledCenter isTopBarVisible={Boolean(isTopBarVisible)}>
      <Spacings.Stack scale="huge" alignItems="center">
        {props.contentBlock.heading && (
          <StyledHeading
            textStyle="headline1"
            isSingleBlog={Boolean(props.contentBlock.singleBlogImage)}
          >
            {props.contentBlock.heading}
          </StyledHeading>
        )}

        {props.contentBlock.subheading && (
          <StyledSubheading textStyle="headline2">
            {props.contentBlock.subheading}
          </StyledSubheading>
        )}

        {props.contentBlock.singleBlogImage?.fluid ? (
          <>
            {props.contentBlock.singleBlogImage.fluid.srcWebp && (
              <Helmet>
                <link
                  rel="preload"
                  as="image"
                  href={
                    props.contentBlock.singleBlogImage.fluid.srcWebp ||
                    undefined
                  }
                  // @ts-ignore: error states imagesrcset does not exist on JSX.IntrinsicElements.link
                  imagesrcset={
                    props.contentBlock.singleBlogImage.fluid.srcSetWebp
                  }
                  imagesizes={props.contentBlock.singleBlogImage.fluid.sizes}
                />
              </Helmet>
            )}
            <StyledImg
              fluid={props.contentBlock.singleBlogImage.fluid as FluidObject}
              alt={props.contentBlock.heading || ""}
              loading="eager"
              fadeIn={false}
            />
          </>
        ) : null}

        {props.contentBlock.richTextImageCaption?.json && (
          <Spacings.Stack scale="medium">
            {documentToReactComponents(
              props.contentBlock.richTextImageCaption.json,
              options,
            )}
          </Spacings.Stack>
        )}
      </Spacings.Stack>
    </StyledCenter>
  );
};

export const BlockBlogHeroFragment = graphql`
  fragment BlockBlogHero_ContentfulBlockBlogHero on ContentfulBlockBlogHero {
    heading
    subheading
    singleBlogImage {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    richTextImageCaption {
      json
    }
  }
`;

export default BlockBlogHero;
