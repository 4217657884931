import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ChevronLeftIcon from "../Icons/ChevronLeftIcon";
import ChevronRightIcon from "../Icons/ChevronRightIcon";
import ResponsiveContainer from "../ResponsiveContainer";

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
`;
const StyledCarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-out;
`;

const StyledCarouselItem = styled.div`
  min-width: 100%;
  position: relative;
  box-sizing: border-box;
`;

const StyledNavigationContainer = styled(ResponsiveContainer)``;

const ResetButton = styled.button`
  all: unset;
  display: inline-block;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  text-align: center;
  text-decoration: none;
  appearance: none;
`;

const StyledDot = styled(ResetButton)`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.gray[600]};
`;

const StyledDotsContainer = styled.div`
  left: 50%;
  bottom: 20px;
  position: absolute;
  transform: translateX(-50%);
  /* stylelint-disable-next-line selector-type-no-unknown */
  ${StyledDot}:not(:last-child) {
    margin-right: 0.3rem;
  }
`;

const StyledButton = styled(ResetButton)`
  top: 50%;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
  position: absolute;
  transform: translate(0%, -50%);

  &:first-of-type {
    left: 10px;
  }

  &:last-of-type {
    right: 10px;
  }
`;

type CarouselProps = {
  hideNavigationArrows?: boolean;
  autoSlide?: boolean;
  slides: React.ReactNode[];
  autoSlideInterval?: number;
};

const Carousel: React.FC<CarouselProps> = ({
  slides,
  autoSlide = false,
  autoSlideInterval = 3000,
  hideNavigationArrows = false,
}) => {
  if (slides.length === 0) return null;

  const [currentSlide, setCurr] = useState(0);

  const previous = () =>
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));

  const next = () =>
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);

  return (
    <StyledContainer>
      <StyledCarouselWrapper
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, idx) => (
          <StyledCarouselItem key={idx}>{slide}</StyledCarouselItem>
        ))}
      </StyledCarouselWrapper>

      <StyledNavigationContainer
        showOn={hideNavigationArrows ? [] : ["desktop", "tablet"]}
      >
        <StyledButton type="button" onClick={previous}>
          <ChevronLeftIcon scale="big" isOnDarkBackground />
        </StyledButton>
        <StyledButton type="button" onClick={next}>
          <ChevronRightIcon scale="big" isOnDarkBackground />
        </StyledButton>
      </StyledNavigationContainer>

      <StyledDotsContainer>
        {slides.map((_, idx) => (
          <StyledDot
            key={idx}
            type="button"
            onClick={() => setCurr(idx)}
            style={{
              backgroundColor: idx === currentSlide ? "white" : undefined,
            }}
          />
        ))}
      </StyledDotsContainer>
    </StyledContainer>
  );
};

export default Carousel;
