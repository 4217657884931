import React from "react";
import styled, { css } from "styled-components";

import Spacings from "../Spacings";
import Switcher from "../Switcher";

export type InfoBoxPriority =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "brand";

const infoBoxStylesByInfoBoxType = (priority: InfoBoxPriority) => {
  switch (priority) {
    case "success":
      return css`
        background: ${(props) => props.theme.palette.auxiliary[600]};
      `;
    case "info":
      return css`
        background: ${(props) => props.theme.palette.gray[50]};
      `;
    case "secondary":
      return css`
        background: ${(props) => props.theme.palette.auxiliary[100]};
      `;
    case "brand":
      return css`
        border: ${(props) => props.theme.borders.width.medium}px solid
          ${(props) => props.theme.palette.brand[500]};
      `;
    case "primary":
    default:
      return css`
        border: ${(props) => props.theme.borders.width.medium}px solid
          ${(props) => props.theme.palette.mint[600]};
      `;
  }
};

const Box = styled.div<{ priority: InfoBoxPriority }>`
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  ${(props) => infoBoxStylesByInfoBoxType(props.priority)}
`;

const StyledIconWrapper = styled.div`
  flex-shrink: 0;
`;

const StyledStack = styled(Spacings.Stack)`
  /* Without this the content might overflow in IE11. */
  width: 100%;
  flex: 1;
  justify-content: center;
`;

const StyledSwitcher = styled(Switcher)`
  > * > :first-child {
    flex-grow: 0;
    flex-basis: auto;
  }
`;

type InfoBoxProps = {
  className?: string;
  priority?: InfoBoxPriority;
  icon: React.ReactNode;
  children?: React.ReactNode;
};

const InfoBox: React.FC<InfoBoxProps> = ({
  icon,
  children,
  className,
  priority = "primary",
}) => (
  <Box className={className} priority={priority}>
    <Spacings.Inset scale="medium">
      <StyledSwitcher scale="medium" threshold="30em">
        <StyledIconWrapper>{icon}</StyledIconWrapper>
        <StyledStack scale="medium">{children}</StyledStack>
      </StyledSwitcher>
    </Spacings.Inset>
  </Box>
);

export default InfoBox;
