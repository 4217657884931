import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import Spacings from "../Spacings";
import Text from "../Text";
import ThreeDotLoader from "../ThreeDotLoader";

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

const Loader = styled(ThreeDotLoader)`
  pointer-events: none;
  transform: translateX(-50%) translateY(-50%);
`;

const StyledInsetSquish = styled(Spacings.InsetSquish)<{ isLoading: boolean }>`
  position: relative;

  /* the div is the text component, so we target anything inside that */
  > div:not(${/* sc-selector */ LoaderContainer}) {
    transition: opacity 0.3s;
    opacity: ${(props) => (props.isLoading ? 0.1 : 1)};
  }
`;

export type ButtonType = "primary" | "secondary" | "flat";

const hasDarkBackgroundByButtonType = {
  flat: false,
  primary: true,
  secondary: false,
};

const disabledButtonStyles = css`
  background: ${(props) => props.theme.palette.gray[400]};
  border-color: ${(props) => props.theme.palette.gray[400]};
  &:focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.palette.gray[400]};
  }
`;

const buttonStylesByButtonType = (
  buttonType: ButtonType,
  isOnDarkBackground: boolean,
) => {
  switch (buttonType) {
    case "primary":
      return css`
        background: ${(props) => props.theme.palette.accent.main};
        border-color: ${(props) => props.theme.palette.accent.main};
        &:focus {
          box-shadow: 0 0 0 3px ${(props) => props.theme.palette.accent[200]};
        }
        &:not(:disabled):active {
          background: ${(props) => props.theme.palette.accent[700]};
          border-color: ${(props) => props.theme.palette.accent[700]};
        }
        .device-can-hover &&:not(:active):not(:disabled):hover {
          background: ${(props) => props.theme.palette.accent[600]};
          border-color: ${(props) => props.theme.palette.accent[600]};
        }
      `;
    case "secondary":
      return css`
        background: ${(props) => props.theme.palette.white};
        border-color: ${(props) => props.theme.palette.accent.main};
        * {
          color: ${(props) => props.theme.palette.accent.main};
        }
        box-shadow: none;
        &:focus {
          box-shadow: 0 0 0 3px ${(props) => props.theme.palette.accent[200]};
        }
        &:not(:disabled):active {
          background: ${(props) => props.theme.palette.accent[700]};
          border-color: ${(props) => props.theme.palette.accent[700]};
          * {
            color: ${(props) => props.theme.palette.white};
          }
          ${/* sc-selector */ Loader}, ${/* sc-selector */ Loader}::before, ${
            /* sc-selector */ Loader
          }::after {
            animation-name: dot-flashing-white;
          }
        }
        .device-can-hover &&:not(:active):not(:disabled):hover {
          background: ${(props) => props.theme.palette.accent[600]};
          border-color: ${(props) => props.theme.palette.accent[600]};
          * {
            color: ${(props) => props.theme.palette.white};
          }
          ${/* sc-selector */ Loader}, ${/* sc-selector */ Loader}::before, ${
            /* sc-selector */ Loader
          }::after {
            animation-name: dot-flashing-white;
          }
        }
      `;
    case "flat":
    default:
      if (isOnDarkBackground) {
        return css`
          background: ${(props) =>
            transparentize(0.9, props.theme.palette.white)};
          border-color: transparent;
          &:focus {
            box-shadow: 0 0 0 3px
              ${(props) => transparentize(0.7, props.theme.palette.white)};
          }
          &:not(:disabled):active {
            background: ${(props) =>
              transparentize(0.7, props.theme.palette.white)};
            border-color: transparent;
          }
          .device-can-hover &&:not(:active):not(:disabled):hover {
            background: ${(props) =>
              transparentize(0.8, props.theme.palette.white)};
            border-color: transparent;
          }
        `;
      }
      return css`
        background: ${(props) => props.theme.palette.brand[200]};
        border-color: ${(props) => props.theme.palette.brand[200]};
        &:focus {
          box-shadow: 0 0 0 3px ${(props) => props.theme.palette.brand.light};
        }
        &:not(:disabled):active {
          background: ${(props) => props.theme.palette.brand[400]};
          border-color: ${(props) => props.theme.palette.brand[400]};
        }
        .device-can-hover &&:not(:active):not(:disabled):hover {
          background: ${(props) => props.theme.palette.brand[300]};
          border-color: ${(props) => props.theme.palette.brand[300]};
        }
      `;
  }
};

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
  cursor: pointer;
  outline: none;
  color: inherit;
  font: inherit;
`;

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: 2px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  outline: none;
  padding: 0;
  border: ${(props) => props.theme.borders.width.medium}px solid;
  box-shadow: 0 2px 4px 0
      ${(props) => transparentize(0.9, props.theme.palette.black)},
    0 3px 6px 0 ${(props) => transparentize(0.85, props.theme.palette.black)};
  ${(props) =>
    props.disabled
      ? disabledButtonStyles
      : buttonStylesByButtonType(props.buttonType, props.isOnDarkBackground)}
`;

export type StyledButtonProps = {
  buttonType: ButtonType;
  disabled: boolean;
  isOnDarkBackground: boolean;
};

export type ButtonProps = {
  buttonType?: ButtonType;
  children?: React.ReactNode;
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  loadingText?: React.ReactNode;
  isOnDarkBackground?: boolean;
  scale?: "small" | "medium" | "big";
} & HTMLButtonProps;

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonType = "flat",
      children,
      id,
      isDisabled = false,
      isLoading = false,
      isOnDarkBackground = false,
      onClick,
      onMouseOver,
      scale = "medium",
      loadingText,
      ...props
    },
    ref,
  ) => (
    <StyledButton
      buttonType={buttonType}
      disabled={isDisabled}
      isOnDarkBackground={isOnDarkBackground}
      id={id}
      onClick={onClick}
      onMouseOver={onMouseOver}
      {...props}
      ref={ref}
    >
      <StyledInsetSquish scale={scale} isLoading={isLoading}>
        <Text
          isOnDarkBackground={
            isDisabled ||
            isOnDarkBackground ||
            hasDarkBackgroundByButtonType[buttonType]
          }
          textStyle={scale === "big" ? "button1" : "button2"}
          as="div"
        >
          {children}
        </Text>
        {isLoading ? (
          <LoaderContainer>
            <Loader
              isActive={isLoading}
              loadingText={loadingText}
              variant={buttonType === "secondary" ? "accent" : "white"}
            />
          </LoaderContainer>
        ) : null}
      </StyledInsetSquish>
    </StyledButton>
  ),
);

const ButtonWithRef = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <Button {...props} ref={ref as (node: HTMLButtonElement | null) => any} />
  ),
);

export default ButtonWithRef;
