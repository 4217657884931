import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

import AccountLink from "@finanzchef24gmbh/design-system/src/AccountLink";
import Text from "@finanzchef24gmbh/design-system/src/Text";
import { getCookiesMap } from "../../utils/cookies";
import Responsive from "../Responsive";
import { usePageContext } from "../../context/PageContext";

export const TOP_HEADER_HEIGHT = "2.5rem";

const StyledContainer = styled.div<{ isTopBarVisible?: boolean }>`
  padding: 0 ${(props) => props.theme.spacings.medium};
  background-color: ${(props) => props.theme.palette.brand[600]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 0rem;
  z-index: 1;
  box-sizing: border-box;
  height: ${TOP_HEADER_HEIGHT};

  @media (max-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: ${(props) => (props.isTopBarVisible ? "block" : "none")};
  }
`;

const StyledResponsive = styled(Responsive)`
  padding: ${(props) => props.theme.spacings.small} 0;
  margin-left: auto;
`;

const StyledText = styled(Text)`
  line-height: 1.7;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.white};
    svg {
      width: 1.1em;
      height: 1.1em;
      fill: ${(props) => props.theme.palette.white};
      @media (max-width: ${(props) => props.theme.layout.tabletBreakpoint}) {
        width: 1.7em;
        height: 1.7em;
      }
    }

    &:hover,
    &:hover span,
    &:hover svg {
      color: ${(props) => props.theme.palette.gray[200]};
      fill: ${(props) => props.theme.palette.gray[200]};
    }
  }
`;

type TopHeaderProps = {
  topNewsBar: ReactElement | null;
};

const TopHeader: React.FC<TopHeaderProps> = ({ topNewsBar }) => {
  const { isTopBarVisible } = usePageContext();
  const [accountLabel, setAccountLabel] = useState("Mein Finanzchef24");
  const showCustomerAccount =
    process.env.GATSBY_FF_SHOW_CUSTOMER_ACCOUNT === "true" || false;
  const customerAccountUrl = process.env.GATSBY_CUSTOMER_ACCOUNT_URL || "";

  useEffect(() => {
    const cookies = getCookiesMap();
    const email = cookies["naca-auth"]?.email;
    if (showCustomerAccount && email) {
      setAccountLabel(email);
    }
  }, [customerAccountUrl]);

  return (
    <StyledContainer isTopBarVisible={isTopBarVisible}>
      {topNewsBar}
      {showCustomerAccount ? (
        <StyledResponsive hideOn={["mobile"]}>
          <StyledText textStyle="headline6" as="span">
            <AccountLink href={customerAccountUrl} label={accountLabel} />
          </StyledText>
        </StyledResponsive>
      ) : null}
    </StyledContainer>
  );
};

export default TopHeader;
